<template lang="pug">
    .faq-item.mb-6
        v-expansion-panels(focusable flat)
            v-expansion-panel
                v-expansion-panel-header.font-weight-bold(color='#f6f6f6')
                    h3.title
                        slot(name='header')
                v-expansion-panel-content.py-5
                    slot(name='body')
                            
</template>

<script>
export default {
  name: "FaqItem"
};
</script>
